/* ********************************  thin 100 *********************************************** */
@font-face {
  // font-family: 'Poppins-Thin';
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Thin.ttf');
  font-weight: 100;
}

// @font-face {
//   font-family: 'Poppins-ThinOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-ThinOblique.ttf');
//   /* font-weight: 100;
//     font-style: italic; */
// }

/* ********************************  very light 200 *********************************************** */
@font-face {
  // font-family: 'Poppins-ExtraLight';
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-ExtraLight.ttf');
  font-weight: 200;
}

// @font-face {
//   font-family: 'Poppins-ExtraLightOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-ExtraLightOblique.ttf');
//   /* font-weight: 200;
//     font-style: italic; */
// }

/* ********************************  light 300 *********************************************** */
@font-face {
  // font-family: 'Poppins-Light';
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Light.ttf');
  font-weight: 300;
}

// @font-face {
//   font-family: 'Poppins-LightOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-LightOblique.ttf');
//   /* font-weight: 300;
//     font-style: italic; */
// }

/* ********************************  regular 400 *********************************************** */
@font-face {
  // font-family: 'Poppins-Regular';
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: 400;
}

// @font-face {
//   font-family: 'Poppins-RegularOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-RegularOblique.ttf');
//   /* font-weight: 500;
//     font-style: italic; */
// }

/* ********************************  normal 500 *********************************************** */
@font-face {
  // font-family: 'Poppins-Medium';
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
}

// @font-face {
//   font-family: 'Poppins-MediumOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-MediumOblique.ttf');
//   /* font-weight: normal;
//     font-style: italic; */
// }

/* ********************************  semi bold 600 *********************************************** */
@font-face {
  // font-family: 'Poppins-SemiBold';
  font-family: 'Poppins';

  src: url('../../assets/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
}

// @font-face {
//   font-family: 'Poppins-SemiBoldOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-SemiBoldOblique.ttf');
//   /* font-weight: 600;
//     font-style: italic; */
// }

/* ********************************  bold 700 *********************************************** */
@font-face {
  // font-family: 'Poppins-Bold';
  font-family: 'Poppins';

  src: url('../../assets/fonts/Poppins/Poppins-Bold.ttf');
  font-weight: 700;
}

// @font-face {
//   font-family: 'Poppins-BoldOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-BoldOblique.ttf');
//   /* font-weight: 700;
//     font-style: italic; */
// }

/* ******************************** very bold 800 *********************************************** */
@font-face {
  // font-family: 'Poppins-ExtraBold';
  font-family: 'Poppins';

  src: url('../../assets/fonts/Poppins/Poppins-ExtraBold.ttf');
  font-weight: 800;
}

// @font-face {
//   font-family: 'Poppins-ExtraBoldOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-ExtraBoldOblique.ttf');
//   /* font-weight: 800;
//     font-style: italic; */
// }

/* ********************************  black 900 *********************************************** */
@font-face {
  // font-family: 'Poppins-Black';
  font-family: 'Poppins';

  src: url('../../assets/fonts/Poppins/Poppins-Black.ttf');
  font-weight: 900;
}

// @font-face {
//   font-family: 'Poppins-BlackOblique';
//   src: url('../../assets/fonts/Poppins/Poppins-BlackOblique.ttf');
//   /* font-weight: 900;
//     font-style: italic; */
// }
