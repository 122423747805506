.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.date {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
    .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium {
      .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
        // background-color: aqua;
        position: absolute;
        left: 0;
        border-radius: 0px;
        width: 100%;
        height: 100%;

        align-items: center;
        justify-content: end;
      }
    }
  }
}
