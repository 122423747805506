@import url('./fonts/fonts.scss');
@import url('./components/components.scss');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins' !important;
}

p {
  font-family: 'Poppins' !important;
}

.gm-control-active.gm-fullscreen-control {
  display: none;
}


// input[type="color"],
// input[type="date"],
// input[type="datetime"],
// input[type="datetime-local"],
// input[type="email"],
// input[type="month"],
// input[type="number"],
// input[type="password"],
// input[type="search"],
// input[type="tel"],
// input[type="text"],
// input[type="time"],
// input[type="url"],
// input[type="week"],
// select:focus,
// textarea {
//   font-size: 16px;
// }