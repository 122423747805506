/* ********************************  regular 400 *********************************************** */
@font-face {
  font-family: 'Sen';
  src: url('../../assets/fonts/Sen/Sen-Regular.ttf');
  font-weight: 400;
}

// @font-face {
//   font-family: 'Sen-RegularOblique';
//   src: url('../../assets/fonts/Sen/Sen-RegularOblique.ttf');
//   /* font-weight: 500;
//     font-style: italic; */
// }

/* ********************************  bold 700 *********************************************** */
@font-face {
  font-family: 'Sen';
  src: url('../../assets/fonts/Sen/Sen-Bold.ttf');
  font-weight: 700;
}

// @font-face {
//   font-family: 'Sen-BoldOblique';
//   src: url('../../assets/fonts/Sen/Sen-BoldOblique.ttf');
//   /* font-weight: 700;
//     font-style: italic; */
// }

/* ******************************** very bold 800 *********************************************** */
@font-face {
  font-family: 'Sen';
  src: url('../../assets/fonts/Sen/Sen-ExtraBold.ttf');
  font-weight: 800;
}

// @font-face {
//   font-family: 'Sen-ExtraBoldOblique';
//   src: url('../../assets/fonts/Sen/Sen-ExtraBoldOblique.ttf');
//   /* font-weight: 800;
//     font-style: italic; */
// }
